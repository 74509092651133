(function ($) {
	'use strict';
	// Navbar JS
	$(window).on('scroll', function () {
		if ($(this).scrollTop() > 200) {
			$('.navbar-area').addClass("is-sticky");
		}
		else {
			$('.navbar-area').removeClass("is-sticky");
		}
	});
	$('.navbar-nav li a').on('click', function (e) {
		var anchor = $(this);
		var href = anchor.attr('href');
		if(href && href.indexOf("#") > -1) {
			$('html, body').stop().animate({
				scrollTop: $(anchor.attr('href')).offset().top - 70
			}, 1500);
		}
		e.preventDefault();
	});
	$(document).on('click', '.navbar-collapse.show', function (e) {
		if ($(e.target).is('a') && $(e.target).attr('class') != 'dropdown-toggle') {
			$(this).collapse('hide');
		}
	});
	//Back To Top
	$(window).on('load', function () {
		$('.top-btn').fadeOut();
	});
	$(window).scroll(function () {
		if ($(this).scrollTop() != 0) {
			$('.top-btn').fadeIn();
		}
		else {
			$('.top-btn').fadeOut();
		}
	});
	$('.top-btn').on('click', function () {
		$("html, body").animate({ scrollTop: 0 }, 3000);
		return false;
	});
	//WOW JS
	new WOW().init();

})(jQuery);


